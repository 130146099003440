import { isDefined } from "@clipboard-health/util-ts";
import { Button } from "@mui/material";
import { isCapacitorPlatform, useToast } from "@src/appV2/lib";
import { APP_V2_USER_EVENTS, logEvent } from "@src/appV2/lib/analytics";
import { useLogEffect } from "@src/appV2/lib/analytics/useLogEffect";
import { BookabilityAttributes } from "@src/appV2/OpenShifts/ShiftAction/types";
import { ZendeskSDK } from "capacitor-zendesk-sdk";

export function UndeterminedShiftButton(params: {
  shiftId: string;
  shiftBookability?: BookabilityAttributes;
}) {
  const { shiftId, shiftBookability } = params;
  const { showErrorToast } = useToast();

  useLogEffect(APP_V2_USER_EVENTS.VIEWED_UNDETERMINED_SHIFT_BUTTON, {
    shiftId,
    shiftBookability,
  });

  return (
    <Button
      variant="outlined"
      size="small"
      fullWidth
      onClick={async () => {
        logEvent(APP_V2_USER_EVENTS.TAPPED_CONTACT_SUPPORT_FOR_UNDETERMINED_BOOKABILITY_STATUS, {
          shiftId,
          shiftBookability,
        });
        if (isCapacitorPlatform()) {
          await ZendeskSDK.showMessaging();
        } else {
          if (!isDefined(window.zE)) {
            showErrorToast("Could not start a support session. Please try again later!");
            return;
          }

          window.zE("messenger", "open");
          window.zE("messenger", "show");
          window.zE("messenger:on", "close", () => {
            window.zE("messenger", "hide");
          });
        }
      }}
    >
      <b>Contact Support</b>
    </Button>
  );
}
