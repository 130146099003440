import "./style.scss";
import { IonPage } from "@ionic/react";
import { logEvent } from "@src/appV2/lib/analytics";
import { getHumanReadableTag } from "@src/lib/utils";
import { FC, useCallback, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";

import { USER_EVENTS } from "../../constants/userEvents";
import { ToolTipProps } from "../dayView/model";
import { recordImpression } from "../pushNotifications/api";
import { removeToastStatus } from "../store/Notifications";
import { Store } from "../store/store.model";

/**
 * @deprecated Use standard `useToast` or other patterns
 * FIXME: Review the usage of this component. Is it even used?
 *
 */
const NotificationsToast: FC<ToolTipProps> = (): any => {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const { status, msg } = useSelector((state: Store) => state.notificationStore);

  let toastMessage = { msg: {}, link: "", messageId: "" };
  if (msg && msg.length > 0) {
    toastMessage = msg[msg.length - 1];
  }
  const { msg: body, link, messageId } = toastMessage;

  const TOAST_DURATION = 7000;

  const timerRef = useRef<ReturnType<typeof setTimeout>>();

  const removeNotification = useCallback(() => {
    const remainingMessages = [...msg];
    remainingMessages.pop();
    dispatch(removeToastStatus(remainingMessages));
    clearTimeout(timerRef.current);
  }, [dispatch, msg]);

  useEffect(() => {
    if (msg.length > 0) {
      timerRef.current = setTimeout(() => removeNotification(), TOAST_DURATION);
      return () => {
        clearTimeout(timerRef.current);
      };
    }
  }, [msg.length, removeNotification]);

  const onClosePressed = async () => {
    const properties = {
      action: "Closed",
      name: msg[0].method,
      medium: "Push",
      content: msg[0].msg,
      source: "",
    };
    logEvent(USER_EVENTS.PUSH_NOTIFICATION_TAPPED, properties);
    recordImpression(messageId, "push");
    removeNotification();
  };

  const onOpenPressed = () => {
    const url = new URL(msg[0].link, "http://example.com");
    const methodValue = url.searchParams.get("method") || msg[0].method;
    const messageId = url.searchParams.get("messageId") || msg[0].messageId;

    logEvent(USER_EVENTS.MESSAGE_OPENED, {
      message_name: getHumanReadableTag(methodValue),
      by: "Worker",
      message: msg[0].msg,
      method: methodValue,
      channel: "push",
      messageId: messageId,
    });
    const properties = {
      action: "Opened",
      name: methodValue,
      medium: "Push",
      content: msg[0].msg,
      source: "",
    };
    logEvent(USER_EVENTS.PUSH_NOTIFICATION_TAPPED, properties);
    recordImpression(messageId, "push");
    if (location.pathname.includes("/home/openShifts")) {
      history.goBack();
      setTimeout(() => {
        history.push(link);
      }, 500);
    } else {
      history.push(link);
    }
    removeNotification();
  };
  if (!toastMessage) {
    return null;
  }

  return (
    <IonPage style={{ zIndex: "auto", contain: "none" }}>
      {status && msg && msg.length > 0 ? (
        <div className="custom-toast">
          <button className="close-btn" onClick={onClosePressed}>
            x
          </button>
          <div role={"button"} tabIndex={0} onClick={onOpenPressed}>
            {body}
          </div>
        </div>
      ) : (
        ""
      )}
    </IonPage>
  );
};

export { NotificationsToast };
