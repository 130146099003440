import { LoadingButton } from "@clipboard-health/ui-react";
import { isDefined, isNullOrUndefined } from "@clipboard-health/util-ts";
import { APP_V2_APP_EVENTS } from "@src/appV2/lib";
import { useLogEffect } from "@src/appV2/lib/analytics/useLogEffect";
import {
  BookabilityAttributes,
  BookabilityDecision,
  BookabilityUnmetCriteria,
} from "@src/appV2/OpenShifts/ShiftAction";
import { ShiftSearchMode } from "@src/appV2/OpenShifts/ShiftAction/api/useClaimShift";
import {
  BookabilityNegotiationMetadata,
  ClaimShiftResponse,
} from "@src/appV2/OpenShifts/ShiftAction/types";
import { useDefinedWorker } from "@src/appV2/Worker/useDefinedWorker";
import { useIsQuizWorkersOnWorkplaceRulesFeatureEnabled } from "@src/appV2/WorkplaceQuiz/utils/useIsQuizWorkersOnWorkplaceRulesEnabled";
import { Shift } from "@src/lib/interface";

import { BlockedShiftButton } from "./Actions/BlockedShiftButton";
import { BookShiftButton } from "./Actions/BookShiftButton";
import { UndeterminedShiftButton } from "./Actions/UndeterminedShiftButton";

export interface ShiftActionButtonProps {
  shift: Shift;
  shiftBookability?: BookabilityAttributes;
  isUrgentShift: boolean;
  buttonStyles?: React.CSSProperties;
  isShiftBookabilityLoading: boolean;
  searchMode: ShiftSearchMode;
  onBookShift: (shift: ClaimShiftResponse) => void;
  onShiftMarkInterest: () => void;
  negotiationMetadata?: BookabilityNegotiationMetadata;
}

export function ShiftActionButton(props: ShiftActionButtonProps) {
  const {
    shift,
    shiftBookability,
    onBookShift,
    buttonStyles,
    isUrgentShift,
    isShiftBookabilityLoading,
    searchMode,
    onShiftMarkInterest,
    negotiationMetadata,
  } = props;
  const worker = useDefinedWorker();

  // shift?.facility should never be undefined or null but adding logs to verify our hypothesis
  useLogEffect(
    APP_V2_APP_EVENTS.UNDEFINED_FACILITY_WHILE_EVALUATING_IS_QUIZ_ENABLED,
    {
      shift,
    },

    // Only log if shift?.facility is undefined or null
    { enabled: isNullOrUndefined(shift?.facility) }
  );

  const isQuizWorkersOnWorkplaceRulesFeatureEnabled =
    useIsQuizWorkersOnWorkplaceRulesFeatureEnabled({
      workplace: {
        quizWorkersOnRules: shift.facility?.quizWorkersOnRules,
      },
    });

  if (isShiftBookabilityLoading && !isDefined(shiftBookability)) {
    return <LoadingButton size="small" isLoading={isShiftBookabilityLoading} />;
  }

  if (!isDefined(shiftBookability)) {
    return <UndeterminedShiftButton shiftId={shift._id ?? ""} />;
  }

  /* If shift cannot be booked and is neither due to instant book being disabled
   * nor quiz being being a requirement, show blocked button
   */
  if (
    shiftBookability.decision === BookabilityDecision.BLOCKED &&
    ![
      BookabilityUnmetCriteria.SHIFT_INSTANT_BOOK_DISABLED,
      BookabilityUnmetCriteria.WORKER_HAS_NOT_PASSED_QUIZ_FOR_WORKPLACE_RULES,
    ].includes(shiftBookability.unmetCriteria?.[0])
  ) {
    return <BlockedShiftButton shiftBookability={shiftBookability} shift={shift} />;
  }

  /* If shift can be booked
   * or is blocked due to instant book being disabled show book button
   * or is blocked due to quiz requirement
   */
  if (
    shiftBookability.decision === BookabilityDecision.ALLOWED ||
    shiftBookability.decision === BookabilityDecision.UNDETERMINED ||
    (shiftBookability.decision === BookabilityDecision.BLOCKED &&
      (shiftBookability.unmetCriteria?.[0] ===
        BookabilityUnmetCriteria.SHIFT_INSTANT_BOOK_DISABLED ||
        (shiftBookability.unmetCriteria?.[0] ===
          BookabilityUnmetCriteria.WORKER_HAS_NOT_PASSED_QUIZ_FOR_WORKPLACE_RULES &&
          isQuizWorkersOnWorkplaceRulesFeatureEnabled)))
  ) {
    return (
      <BookShiftButton
        shiftBookability={shiftBookability}
        shift={shift}
        agentId={worker.userId}
        isUrgentShift={isUrgentShift}
        searchMode={searchMode}
        onBookShift={onBookShift}
        onShiftMarkInterest={onShiftMarkInterest}
        negotiationMetadata={negotiationMetadata}
        buttonStyles={buttonStyles}
      />
    );
  }

  return <UndeterminedShiftButton shiftId={shift._id ?? ""} shiftBookability={shiftBookability} />;
}
